.servicecard {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 0;
    transition: transform 20.5s ease;
  }
  /* css for service cards */
main {
    flex: 1;
}
.partnerContainer {
    position: relative;
    background-image: url('../../../../assets/servicesImg/services.png');
    background-size: cover; /* Ensures the image covers the container */
    background-position: center;
    height: 440px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #553619;
    // color: #3b999f;
    text-transform: uppercase;
    font-size: 2.5rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  
    /* Ensure image is fully visible on mobile */
    @media (max-width: 768px) {
      height: 300px; /* Adjust height for mobile screens */
      background-size: contain; /* Ensures the full image is shown */
      background-repeat: no-repeat; /* Prevents background from repeating */
    }
  
    @media (max-width: 480px) {
      height: 200px; /* Further reduce height for smaller screens */
      font-size: 1.8rem; /* Adjust font size for smaller screens */
      background-size: contain; /* Ensures the full image is visible */
      background-repeat: no-repeat;
    }
  }
  

.serviceCards {
   margin: 0;
}
.card .serviceHeading {
    /* background-image: rgb(200, 215, 228); */
    // background-image: linear-gradient(to bottom , #e9c9aa 80%, #e8cec5 20% );
    /* // margin-top: 30px; > this style is previous one i change below value */
    // margin-top: 3rem;
    padding-top: 10px;
    // border:1px solid #77b1b5;
    border-top: 1px solid  #77b1b5;

}
.card .dInlineflex .p-2 img {
    border-radius: 20px;
    border: 10px solid #fff;
}

.card .dInlineflex a{
    text-decoration: none;
    font-size: 15px;color: black;
    font-family: sans-serif;
    background-image: linear-gradient(to bottom , #ff000000 , rgba(255, 255, 0, 0.434));
}
/* service individual section */
.gCol6 ul li {
list-style-type: none;
}
.gCol6 h1 {
    color: rgba(0, 0, 255, 0.626);
}
.gCol6 h4 {
    color: rgba(23, 23, 101, 0.715);
}
.gCol6 p {
    color: black;
}
.gCol6 img {
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effects */
}
.p-2 img:hover {
    transform: rotate(02deg) scale(1.1); /* Rotate slightly and scale up */
  box-shadow: 0px 4px 20px rgba(3, 93, 141, 0.511); /* Add shadow on hover */
}
.p-2 {
  margin-bottom: 0;
  padding-bottom: 0;
}
.p-2 a {
    border: none;
}

.p-2 a img {
    border: none; /* Ensure no borders around images */
  }
.dInlineflex {
    flex-wrap: wrap;
    justify-content: center;
  }

/* Style for the service content container */
#service-content {
    background-color: #f8f9fa; /* Adjust background color if needed */
    margin-top: 80px;
    transition: height 0.3s ease; /* Smooth height transition */
  }
  
  .serviceIndividual {
    padding: 20px;
    border: 1px solid #ddd;
    background-color: #fff;
    margin-bottom: 20px;
  }
 
 /* Default style for larger screens (desktop and tablet) */
.serviceIndividual .grid {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    gap: 20px;
   
}

.serviceIndividual .g-col-6 {
    flex: 1; /* Take up equal space */
    margin: 0 20px; /* Add some spacing */
}

.serviceIndividual img {
    max-width: 100%;
    height: auto;
    border-radius: 10px; /* Optional for a rounded corner image */
}
.contentWrapper {
    margin-bottom: 20px; /* Adjust this value as needed */
}
.clearfix::after {
    content: "";
    display: table;
    clear: both;
}
.customUl {
    margin-left: 26%;
    line-height: 2rem;
    list-style-type: none;
}


  @media (max-width: 768px) {
    .dInlineflex {
        flex-direction: column; /* Stack icons vertically */
        gap: 10px; /* Reduce gap between icons */
    }

    .p-2 img {
        width: 60px; /* Reduce icon size for mobile */
        margin: 0 auto; /* Center icons */
    }

    .p-2 span {
        font-size: 1rem; /* Smaller text for mobile */
        text-align: center;
    }
    .customUl {
        margin-left: 0;
    
    }
  }
  /* For very small mobile screens (up to 480px) */
@media (max-width: 480px) {
    .p-2 img {
        width: 50px; /* Further reduce icon size */
    }

    .p-2 a {
        font-size: 12px; /* Even smaller text */
    }
    .p-2 {
        flex: 1 1 calc(33.333% - 10px);
        max-width: 100%;
    }
    .customUl {
        margin-left: 0;
    
    }
}



/* Responsive for mobile screens (max-width: 768px) */
@media (max-width: 768px) {
    .serviceIndividual .grid {
        flex-direction: column; /* Stack items vertically */
        text-align: justify;
    }

    .serviceIndividual .g-col-6 {
        margin: 10px 0; /* Add vertical margin between sections */
    }

    .serviceIndividual img {
        width: 100%; /* Adjust the image width for smaller screens */
        margin: 0 auto; /* Center the image */
    }
    .customUl {
        margin-left: 0;
    
    }
}


/* Servicepage.module.scss */

.contentWrapper {
    /* Styles for content wrapper */
  }
  
  .clearfix::after {
    content: "";
    display: table;
    clear: both;
  }
  
  .partnerContainer {
    /* Styles for the partner container */
  }
  
  .serviceCards {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .card {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .serviceHeading {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .serviceheading {
    font-family: Georgia, 'Times New Roman', Times, serif;
    color: #3b999f;
  }
  
  .serviceContent {
    text-align: center;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: large;
    font-weight: 200;
    color: black;
  }
  
  .dInlineflex {
    display: inline-flex;
  }
  
  .servicecard {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .serviceIndividual {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .customUl {
    list-style-type: none;
    padding-left: 0;
  }
  
  .customUl li {
    text-align: left;
    font-weight: bold;
    color: black;
  }
  
  .serviceImageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .back-to-top {
    float: right;
    margin-right: 32px;
    padding: 5px;
    text-decoration: none;
    color: black;
    font-size: 25px;
  }
  
  .back-to-top:hover {
    color: #3b999f;
  }
  