

.modal-content.custom-modal-content {
  border: none !important;
  border-radius: 0 !important;
  background-color: transparent !important;
}

.projectName {
    position: relative;
    color: black;
    transition: all 0.3s ease;
  }
  
  .projectName::after {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    bottom: -5px;
    left: 0;
    background-color: orange;
    transition: width 0.3s ease;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  
  .projectName:hover::after {
    width: 100%;
  }
  
  .projectCard {
    opacity: 0;
    transform: translateY(20px);
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  }
  
  .projectCard img {
    width: 130px;
    height: 130px;
    object-fit: cover;
    transition: transform 0.4s ease-in-out;
  }
  
  .projectCard:hover {
    transform: scale(1.1);
  }
  
  .helpSection {
    background-image: url('../../../../assets/images/contact\ us\ t.png');
    background-size: cover;
    background-position: center;
    height: 450px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #B87333;
    font-size: 2em;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    padding-left: 20px;
    margin: 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    
    // Responsive adjustments
    @media (max-width: 768px) {
      font-size: 1.5em;  // Smaller text on medium screens
      padding-left: 15px;
    }
  
    @media (max-width: 480px) {
      font-size: 1.2em;  // Even smaller text on small screens
      padding-left: 10px;
      height: 300px;  // Reduce height on smaller screens
    }
  }
  
  .contactButton {
    margin-top: 20%;
    margin-left: -17%;
    border: none;
    background-color: rgb(75, 180, 221);
    padding: 10px;
    font-size: 20px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    border-radius: 5px;
    color: white;
    
    // Responsive adjustments for the button
    @media (max-width: 768px) {
      margin-left: -10%;  // Adjust button position on smaller screens
      font-size: 18px;  // Smaller font size
    }
  
    @media (max-width: 480px) {
      margin-left: -5%;  // Further adjust for very small screens
      font-size: 16px;  // Even smaller font size
      padding: 8px;  // Reduce padding on small screens
    }
  }
  

  // .helpSection {
  //   background-image: url('../../../../assets/images/contact\ us\ 22.png');
  //   // background-image: url('../../../../assets/images/contactusimages6.png');
  //   background-size: cover;
  //   background-position: center;
  //   height: 370px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: flex-start;
  //   color: #B87333;
  //   font-size: 2em;
  //   text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  //   padding-left: 20px;
  //   margin: 0;
  //   // margin-top: -0px;
  //   font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
  // }

  
  // .contactButton {
  //   margin-top: 20%;
  //   margin-left: -17%;
  //   border: none;
  //   background-color: rgb(75, 180, 221);
  //   padding: 10px;
  //   font-size: 20px;
  //   font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  //   border-radius: 5px;
  //   color: white;
  // }
  
  .sectionHeader {
    // color: #B87333;
    color: #3b999f;
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    text-decoration: underline;
  }
  
  .locationText {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    // color: #B87333;
    color: #3b999f;
    font-size: 40px;
    text-align: center;
    text-decoration: underline;
  }
  
  .locationContainer {
    margin-bottom: 20px;
    width: 60%;
  }

  .locationImg{
    width: 361px;
    height: 434px;
    height: auto;
    /* border-radius: 15px; Border radius applied */
    transition: transform 0.5s ease-in-out;
    margin-left: 200px;
    margin-left: 16px;
    margin-top: 35px;
  }
  .locationImg:hover {
    transform: scale(1.05);
  }
  
  .locationInfo {
    position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: opacity 0.5s ease-in-out;
      color: white;
      background-color: rgba(0, 0, 0, 0.7);
      padding: 25px;
      width: 361px;
      height: 434px;
      text-align: center;
  }
  
  .img-wrapper:hover .locationInfo {
    opacity: 1;
  }
  
  .closeButton {
    border: none;
    background-color: white;
    color: black;
    width: 25px;
    height: 25px;
    margin-left: 90%;
    margin-top: -20px;
  }
  
  
  
  