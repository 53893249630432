@import url("https://fonts.googleapis.com/css2?family=Spline+Sans:wght@300;400;500;600;700&display=swap");
*,
*:after,
*:before {
  box-sizing: border-box;
}

:root {
  --c-white: #fff;
  --c-black: #000;
  --c-ash: #eaeef6;
  --c-charcoal: #a0a0a0;
  --c-void: #141b22;
  --c-fair-pink: #FFEDEC;
  --c-apricot: #FBC8BE;
  --c-coffee: #754D42;
  --c-del-rio: #917072;
  --c-java: #1FCAC5;
  --c-titan-white: #f1eeff;
  --c-cold-purple: #a69fd6;
  --c-indigo: #6558d3;
  --c-governor: #4133B7;
}



.article {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2.5rem;
  /* width: 90%;
  max-width: 1000px; */
  margin: 0px auto;
  font-family: "Spline Sans", sans-serif;
  line-height: 1.5;
  background-color: var(--c-ash);
}

.information {
  background-color: var(--c-white);
  padding: 1.5rem;
  border-radius: 16px;
  box-shadow: 0 30px 30px -25px rgba(65, 51, 183, 0.25);
  max-width: 300px;
  height: 345px;
}
.information .tag {
  display: inline-block;
  background-color: var(--c-titan-white);
  /* color: var(--c-indigo); */
  color: #d7a270;
  font-weight: 600;
  font-size: 0.875rem;
  padding: 0.5em 0.75em;
  line-height: 1;
  border-radius: 6px;
}
.information .tag + * {
  margin-top: 1rem;
}
.information .title {
  font-size: 1.5rem;
  color: var(--c-void);
  line-height: 1.25;
}
.information .title + * {
  margin-top: 1rem;
}
.information .info {
  color: var(--c-charcoal);
}
.information .info + * {
  margin-top: 1.25rem;
}
.information .button {
  font: inherit;
  line-height: 1;
  background-color: var(--c-white);
  border: 2px solid var(--c-indigo);
  color: var(--c-indigo);
  padding: 0.5em 1em;
  border-radius: 6px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
}
.information .button:hover, .information .button:focus {
  background-color: var(--c-indigo);
  color: var(--c-white);
}
.information .details {
  display: flex;
  gap: 1rem;
}
.information .details div {
  padding: 0.75em 1em;
  background-color: var(--c-titan-white);
  /* background-color: #eee1d4; */
  border-radius: 8px;
  display: flex;
  flex-direction: column-reverse;
  gap: 0.125em;
  flex-basis: 50%;
}
.information .details dt {
  font-size: 0.875rem;
  color: var(--c-cold-purple);
}
.information .details dd {
  color: var(--c-indigo);
  font-weight: 600;
  font-size: 1.25rem;
}

.plan {
  padding: 10px;
  /* background-color: var(--c-white); */
  background-color: #3B999F;
  color: var(--c-del-rio);
  border-radius: 16px;
  box-shadow: 0 30px 30px -25px rgba(65, 51, 183, 0.25);
  max-width: 300px;
}
.plan strong {
  font-weight: 600;
  color: var(--c-coffee);
}
.plan .inner {
  padding: 20px;
  padding-top: 40px;
 /* background-color: var(--c-fair-pink); */
 background-color:whitesmoke;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
}
.plan .pricing {
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--c-apricot);
  border-radius: 99em 0 0 99em;
  display: flex;
  align-items: center;
  padding: 0.625em 0.75em;
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--c-coffee);
}
.plan .pricing small {
  color: var(--c-del-rio);
  font-size: 0.75em;
  margin-left: 0.25em;
}
.plan .title {
  font-weight: 600;
  font-size: 1.25rem;
  color: var(--c-coffee);
}
.plan .title + * {
  margin-top: 0.75rem;
}
.plan .info + * {
  margin-top: 1rem;
  color: var(--c-black);
}
.plan .features {
  display: flex;
  flex-direction: column;
}
.plan .features li {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.plan .features li + * {
  margin-top: 0.75rem;
}
.plan .features .icon {
  background-color: var(--c-java);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--c-white);
  border-radius: 50%;
  width: 20px;
  height: 20px;
}
.plan .features .icon svg {
  width: 14px;
  height: 14px;
}
.plan .features + * {
  margin-top: 1.25rem;
}
.plan button {
  font: inherit;
  background-color: var(--c-indigo);
  border-radius: 6px;
  color: var(--c-white);
  font-weight: 500;
  font-size: 1.125rem;
  width: 100%;
  border: 0;
  padding: 1em;
}
.plan button:hover, .plan button:focus {
  background-color: var(--c-governor);
}

/* Inside Stories css starts here */

.inside-stories {
  text-align: center;
  
}
.inside-stories h2 {
  font-size: 36px;
  margin-bottom: 20px;
  /* background-image: linear-gradient(to right , rgba(255, 0, 0, 0.567) , yellow); */
  background-color:#3B999F;
}
.stories-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.story {
  overflow: hidden;
  margin-bottom: 20px;
  transition: transform 0.3s ease; /* Smooth zoom */
}
.story img {
  width: 60%;
  border-radius: 10px;
  margin-bottom: 10px;
}
.story img:hover {
  transform: scale(1.1); /* Zoom effect */
}
.story h3 {
  font-size: 24px;
  color: #333;
}
.story p {
  font-size: 16px;
  color: #666;
}
.story-left {
  width: 60%; /* Left image occupies 60% width */
}
.story-column {
  display: flex;
  flex-direction: column;
  width: 35%; /* Right column for two images */
}

/* Media query for mobile responsiveness */
@media (max-width: 768px) {
  .stories-container {
      flex-direction: column;
  }
  .story-left, .story-column {
      width: 100%;
  }
  .story-column {
      flex-direction: column;
  }
}


/* Slide-in animation */
@keyframes slideIn {
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Apply animation to flip cards */
.testimonialcard {
  animation: slideIn 0.6s ease-out forwards;
}

/* Staggered animation for multiple cards */
.testimonialclecard:nth-child(1) {
  animation-delay: 0.2s;
}

.testimonialcard:nth-child(2) {
  animation-delay: 0.4s;
}

.testimonialcard:nth-child(3) {
  animation-delay: 0.6s;
}


/* FlipCards.css */

/* Flipcard container styles */
.flipcard-container {
  perspective: 1000px;
  margin: 20px;
}

.flipcard {
  width: 300px;
  height: 400px;
  transform-style: preserve-3d;
  transition: transform 0.6s;
}

.flipcard:hover {
  transform: rotateY(180deg);
}

.flipcard-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.6s;
}

.flipcard-front,
.flipcard-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  padding: 20px;
  font-family: system-ui;
}

.flipcard-front {
  background-color: #333;
  color: white;
}

.flipcard-back {
  background-color: #333;
  color: white;
  transform: rotateY(180deg);
}

/* Styling for the content inside the flipcard front */
.flipcard-front img {
  width: 80px;
}

.flipcard-front h2,
.flipcard-front p {
  font-family: system-ui;
}

/* Styling for the list on the back of the flipcard */
.flipcard-back ul {
  color: white;
  font-family: system-ui;
  text-align: left;
  list-style-type: none;
  margin-left: 30px;
}

.flipcard-back ul li {
  margin-bottom: 10px;
}

/* Learn More button styles */
.flipcard-back .btn-primary {
  font-family: system-ui;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  text-align: center;
}

.flipcard-back .btn-primary:hover {
  background-color: #0056b3;
}



/* Ensure the flip card is responsive */
.flipcard-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
  height: 100%; /* Ensure it occupies available height */
}

.flipcard {
  width: 100%;
  max-width: 300px;  /* Adjust the card width */
  height: 400px; /* Define a fixed height for the card */
  perspective: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flipcard-inner {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.6s;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Ensures the text is centered inside the flipcard */
.flipcard-front, .flipcard-back {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;
  box-sizing: border-box;
}

.flipcard-front {
  background-color: #3b999f;
  color: white;
}

.flipcard-back {
  background-color: #333;
  color: white;
  transform: rotateY(180deg);
}

.flipcard:hover .flipcard-inner {
  transform: rotateY(180deg);
}

/* Ensure the title and description inside the card are centered */
.flipcard-front h2, .flipcard-back h2 {
  margin-bottom: 15px;
  font-family: system-ui;
  text-align: center;
}

.flipcard-front p, .flipcard-back p {
  font-family: system-ui;
  text-align: center;
  margin-bottom: 10px;
}

.flipcard-back ul {
  list-style-type: none;
  padding: 0;
  text-align: left;
  margin-left: 20px;
  font-family: system-ui;
}

/* Button Styles */
.btn {
  background-color: rgb(92, 171, 202);
  color: white;
  font-family: system-ui;
  border: none;
  padding: 10px 20px;
  margin-top: 15px;
  cursor: pointer;
}

.btn:hover {
  background-color: #5b9dba;
}





/* Inside Stories Section */
.inside-stories {
  padding: 50px;
  text-align: center;
}

.inside-stories h2 {
  font-family: system-ui;
  font-size: 2rem;
}

/* Stories Container */
.stories-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

/* Story Left Box */
.story-left {
  flex: 1;
  text-align: left;
}

.story-left img {
  max-width: 100%;
  height: auto;
  margin-bottom: 15px;
}

.story-left h3 {
  font-family: system-ui;
  font-size: 1.5rem;
}

.story-left h6 {
  font-family: system-ui;
  color: #3b999f;
  width: 60%;
  margin-left: 20%;
  font-size: 1rem;
}

/* Story Column Section */
.story-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.story-column .story {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.story-column .story img {
  max-width: 100%;
  height: auto;
  margin-bottom: 15px;
}

.story-column .story h3 {
  font-family: system-ui;
  font-size: 1.5rem;
}

.story-column .story h6 {
  font-family: system-ui;
  color: #3b999f;
  width: 80%;
  margin-left: 10%;
  font-size: 1rem;
}

/* Left Side Story */
.story-left {
  flex: 1;
  max-width: 45%;
  text-align: center;
}

.story-left img {
  width: 90%; /* Keep the width the same */
  height: auto; /* Set a fixed height */
  object-fit: cover; /* This will maintain aspect ratio and fill the box */
  border-radius: 8px;
  display: block;
  /* margin: 10px auto; */
  margin-top:15%;
  margin-left: 5%;
}

/* Right Side - Two Smaller Stories */
.story-column {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 45%;
  text-align: center;
}

.small-story {
  /* background: #f5f5f5; */
  padding: 12px;
  border-radius: 8px;
  text-align: center;
  margin-bottom: 10px;
  overflow: hidden;
}

.small-story img {
  width: 50%; /* Smaller image */
  height: auto;
  border-radius: 6px;
  display: block;
  margin: 10px auto;
}

/* Media Queries for Responsiveness */

/* Tablet devices */
@media screen and (max-width: 768px) {
  .inside-stories h2 {
    font-size: 1.8rem;
  }

  .stories-container {
    flex-direction: column;
    align-items: center;
  }

  .story-left,
  .story-column {
    max-width: 100%;
    flex: 1;
    text-align: center;
  }

  .story-left img,
  .story-column .story img {
    width: 80%; /* Adjust image width for tablet */
  }

  .story-column .story h3,
  .story-left h3 {
    font-size: 1.3rem;
  }

  .story-column .story h6,
  .story-left h6 {
    font-size: 1rem;
  }

  .small-story img {
    width: 70%; /* Adjust image width for smaller stories */
  }
}

/* Mobile devices */
@media screen and (max-width: 480px) {
  .inside-stories h2 {
    font-size: 1.5rem;
  }

  .stories-container {
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  .story-left,
  .story-column {
    max-width: 100%;
    flex: 1;
  }

  .story-left img,
  .story-column .story img {
    width: 90%; /* Further adjust image width for mobile */
  }

  .story-column .story h3,
  .story-left h3 {
    font-size: 1.1rem;
  }

  .story-column .story h6,
  .story-left h6 {
    font-size: 0.9rem;
  }

  .small-story img {
    width: 80%; /* Adjust image width for small devices */
  }
}
