/* Blog.css */

/* Partnership Header Section */
.partnership-containers {
  position: relative;
  background-image: url('../../../../assets/blogImages/blog.png');
  background-size: cover;
  background-position: center;
  height: 440px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #553619;
  text-transform: uppercase;
  font-size: 2.5rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.partnership-containers * {
  position: relative;
  z-index: 2;
  font-family: system-ui;
}

/* Header Section for the Blog */
.container .text-center {
  color: #333;
  font-weight: bold;
}

/* "Click Here" Card */
.card-container {
  margin-top: 20px;
  overflow-x: auto;
}

.card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.card h4 {
  color: #3b999f;
  font-family: system-ui;
}

.card p {
  color: black;
  font-family: system-ui;
}

/* Button Styling */
.btn-primary {
  background-color: #3b999f;
  border: none;
}

/* Technologies Cards */
.card-body img {
  width: 70%;
  height: 240px;
  object-fit: cover;
  margin-bottom: 20px;
}

.card-body h5 {
  font-size: 24px;
  font-family: system-ui;
  color: #3b999f;
  text-decoration: underline;
}

.card-body p {
  font-family: system-ui;
  color: #666;
  margin-top: 10px;
  color: black;
}

/* Responsive Font Size */
@media (max-width: 768px) {
  .partnership-containers {
    font-size: 1.5rem;
  }
}
