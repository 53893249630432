* {
  box-sizing: border-box; /* Ensure padding and borders are included in total width/height */
}

.terms-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f5f5f5;
  width: 100%; /* Full width */
}

.terms-card {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 15px;
  width: 100%; /* Make card full width */
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  animation: fadeInUp 0.8s ease forwards;
  border: 1px solid black;
}

.terms-heading {
  font-size: 1.5em;
  color: #333;
}

.terms-subheading {
  /* font-size: 1.2em; */
  color:skyblue;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  margin-top: 10px;
  margin-bottom: 10px;
}

.terms-card p {
  color: #444;
  line-height: 1.6;
  margin: 10px 0;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}