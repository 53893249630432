/* Roas.css */
.container {
  font-family: Arial, sans-serif;
  padding: 20px;
}

.header {
  text-align: center;
  margin-bottom: 40px;
  font-size: 25px;
}

.section {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}

.content {
  flex: 1 1 300px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.heading {
  color: #3B999F;
  margin-bottom: 10px;
}

.text {
  color: black;
  line-height: 1.6;
}

.ul {
  color: black;
  line-height: 1.8;
  list-style-type: "→";
  margin: 0;
  padding: 0 0 0 20px;
}

.image-container {
  flex: 1 1 300px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  width: 100%;
  max-width: 500px;
  height: auto;
  border-radius: 8px;
}

.explanation {
  line-height: 1.8;
  color: #333;
  margin-bottom: 40px;
}

.card {
  flex: 1 1 300px;
  padding: 20px;
  border: 2px solid #eaeaea;
  border-radius: 10px;
  /* background-color: #F4F8FB; */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.card-heading {
  color: #3B999F;
  margin-bottom: 10px;
}

.card-subheading {
  color: #3B999F;
  font-size: 16px;
}

.cta {
  background-color: #F4F8FB;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}
