.container {
  padding: 10px;
}

.custom-hover-card {
  border-radius: 10px; /* Reduced radius for compactness */
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
  position: relative;
  background-color: #f8f9fa;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.custom-hover-card:hover {
  transform: scale(1.03); /* Slight scale effect */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.custom-card-img-top {
  border-radius: 10px 10px 0 0;
  transition: transform 0.3s;
  width: 100%;
  height: 150px; /* Reduced height for more compact card */
  object-fit: cover;
}

.custom-hover-card:hover .custom-card-img-top {
  transform: scale(1.05);
}

.card-body {
  text-align: center;
  padding: 10px; /* Reduced padding */
}

.card-title {
  font-size: 1rem; /* Reduced font size */
  margin-bottom: 5px;
}

.designation {
  color: #3B999F;
  font-weight: bold; /* Font weight instead of 'font-family: bold' */
  font-size: 0.9rem; /* Slightly smaller font size */
  margin-bottom: 10px;
}

.custom-additional-info {
  opacity: 0;
  transition: opacity 0.5s ease;
  margin-top: 10px;
  font-size: 0.8rem; /* Smaller font size for the additional info */
}

.custom-hover-card:hover .custom-additional-info {
  opacity: 1;
  background-color: rgba(65, 153, 156, 0.9);
  color: white;
  padding: 8px;
  border-radius: 5px;
}

.tag {
  color: #41999c;
  text-align: center;
  font-size: 1.5rem; /* Smaller size for the title */
  margin-bottom: 20px;
}

.maindiv {
  background-color: whitesmoke;
  margin-top: 30px;
  padding-top: 20px;
}

@media (max-width: 768px) {
  .col-12 {
    max-width: 100%;
  }
}
