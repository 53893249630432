.header {
  background-image: url('../../../../assets/images/careerer.png');
  background-size: cover;
  background-position: center;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: black;
  font-size: 2em;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  padding-left: 20px;
  margin: 0;
  font-family: system-ui;
}
// .header::before {
//   content: "";
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 85%;
//   background-color: rgba(0, 0, 0, 0.4); /* Adjust opacity to control darkness */
//   z-index: 1;
// }
.CareeresBg{
  background-color: #f1efefeb;
  width: 100%;
}

.marquee {
  font-family:system-ui;
  // color: #B87333;
  color: black;
margin-top: 32%;
}

.sectionTitle {
  // color: #B87333;
  color: #3b999f;
  text-align: center;
  padding-top: 30px;
  font-family:'system-ui';
  text-decoration: underline;
  margin-top: 30px;
}

.animatedCard {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 2.5s ease, transform 2.5s ease;
}

.visible {
  opacity: 1;
  transform: translateY(0);
}

.tag {
  // background-color: #ffe6ce;
  background-color: white;
  border-color: #ffe6ce;
  color:#3b999f;
  padding: 5px 10px;
  border-radius: 5px;
  font-family: system-ui;
  border: 0.5px solid #3b999f;
}

.applyButton {
  background-color: rgb(90, 178, 213);
  color: whitesmoke;
}

.reviewsTitle {
  color: #e49448;
  font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
}

.reviewCard {
  background-color: #f9f9f9;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.reviewAuthor {
  font-weight: bold;
  font-family: system-ui;
}

.reviewText {
  font-family: 'Arial', sans-serif;
  color:#B87333;
  font-family: system-ui;
}

.stars {
  color:gray;
  font-size: 1.2em;
  font-family: system-ui;
}
